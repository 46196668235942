<template>
  <div class="my-mind-content-father-box">
    <div :class="show == 1 ? 'my-mind-content-1' : 'my-mind-content-2'">
      <!-- <div
        v-if="show == 1"
        class="my-mind-list-head my-mind-list"
        style="background-color: #f5f5f5; height:30px; dispaly:none"
      >
        <div class="my-mind-list-text">
          <div class="my-mind-main-heading" style="padding-left: 10px">
            {{ getString(strings.Mind_Mindmap_Deleted_Filename) }}
          </div>
        </div>
        <div
          :style="{ marginLeft: (fullWidth - titleExceptWidth + 10) + 'px' }"
          class="my-mind-time-title"
        >
          {{ getString(strings.Edit_Time) }}
        </div>
      </div> -->
      <template v-if="data != null">
        <div
          v-for="(item, index) in data"
          :key="index"
          class="my-mind-list-box"
          @contextmenu.prevent.stop="showMenu(item, !showMenuOpen, index)"
        >
          <div v-if="item.isDir == false" class="my-mind-list" @mouseleave="gridCloseMoreMenu()">
            <template v-if="isElectron">
              <div
                :id="'my-mind-list' + item.mindMapId"
                class="my-mind-list hover-mind-list"
                @click="openMind(item)"
              >
                <img
                  class="my-mind-img"
                  src="../../../assets/img/user/mind_file_icon.svg"
                  v-real-img="checkImgSrc(item.cover, item)"
                  alt=""
                />
                <img
                  class="local-cloud-icon"
                  v-if="(item.isHistory == null || item.isHistory == false) && item.path != null && item.path.length > 0"
                  src="../../../assets/img/user/local_cloud_icon.svg"
                  alt=""
                  title="已同步"
                />
                <div class="my-mind-list-text">
                  <div
                    v-if="item.alias == '' || item.alias == null"
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    v-else
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
                    :title="item.alias"
                  >
                    {{ item.alias }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
                  >
                    {{ item.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{
                    dateFormat(
                      "YYYY/mm/dd HH:MM",
                      new Date(item.modifyTime * 1000)
                    )
                  }}
                </div>
              </div>
            </template>
            <template v-else>
              <a
                class="my-mind-item"
                :id="'my-mind-list' + item.mindMapId"
                :href="'/mindmap?id=' + item.mindMapId + '&t=' + item.modifyTime"
                target="_blank"
              >
                <!-- <img v-if="item.cover != ''" class="my-mind-img" :src="checkImgSrc(item.cover)" alt=""> -->
                <img
                  class="my-mind-img"
                  src="../../../assets/img/user/mind_file_icon.svg"
                  v-real-img="checkImgSrc(item.cover)"
                  alt=""
                />
                <div class="my-mind-list-text">
                  <div
                    class="my-mind-main-heading"
                    :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </div>
                  <div
                    class="my-mind-main-subheading"
                    :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
                  >
                    {{ item.subtitle }}
                  </div>
                </div>
                <div class="my-mind-time">
                  {{
                    dateFormat(
                      "YYYY/mm/dd HH:MM",
                      new Date(item.modifyTime * 1000)
                    )
                  }}
                </div>
              </a>
            </template>

            <!-- <div @click="pageFile(3,item)" class="gray-box">
                </div> -->
            <div
              class="more-menu-list"
              @click.stop="showMenu(item, !showMenuOpen, index)"
            >
              <!-- @contextmenu.prevent.stop="" -->
              <!-- @mouseover.stop="mouseoverOtherMoreMenu(index)" -->
              <a-icon class="more-icon" type="more" />
              <div
                v-show="
                  !!showMenuData &&
                  showMenuData.id == item.id &&
                  showMenuOpen == true
                "
                class="more-menu-list-position"
                :class="'more-menu-list-position-' + index"
                :style="{ top: menuTop + 'px',right:menuRight + 'px' }"
              >
                <div @click.stop="pageFile(3, item)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon"
                    src="../../../assets/img/myMindIcon/open01.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.My_Mind_Open) }}
                  </div>
                </div>
                <!-- 重命名 -->
                <!-- <div @click.stop="folderRenameMap(item,index)" class="each-menu-box">
                  <img class="each-menu-box-icon-1" src="../../../assets/img/myMindIcon/copyMap.png" alt="">
                  <div class="each-menu-box-name">
                      {{ getString(strings.User_Folder_Rename) }}
                  </div>
                </div> -->
                <div
                  v-show="item.isHistory == null || item.isHistory == false"
                  @click.stop="folderRenameMap(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/rename02.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Folder_Rename) }}
                  </div>
                </div>
                <!-- 复制导图 -->
                <div 
                v-show="item.isHistory == null || item.isHistory == false"
                @click.stop="copyMap(item, index)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/copyMap.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Copy_Mind_Map) }}
                  </div>
                </div>
                <!-- <div  @click.stop="shareFile(item,index)" class="each-menu-box">
                    <img class="each-menu-box-icon-1" src="../../../assets/img/myMindIcon/delete04.png" alt="">
                    <div class="each-menu-box-name">
                      分享风暴
                    </div>
                  </div> -->
                <div 
                v-show="item.isHistory == null || item.isHistory == false"
                @click.stop="moveCheck(item, index)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/set03.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Move_To) }}
                  </div>
                </div>
                <div v-show="item.isHistory == null || item.isHistory == false"
                  @click.stop="importGroup(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/export_group.svg"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{
                      getString(strings.Mind_Mind_Import_Into_A_Shared_Group)
                    }}
                  </div>
                </div>
                <div
                  v-show="item.isHistory == null || item.isHistory == false"
                  @click.stop="importArchive(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1 archive"
                    src="../../../assets/img/myMindIcon/archive.svg"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Mind_Archive) }}
                  </div>
                </div>
                <div
                  @click.stop="removeFile(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/delete04.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div @click="pageFile(3, item)" @mouseleave="gridCloseMoreMenu()" v-else class="my-mind-list">
            <span
              v-if="item.colorCode == 0 || item.colorCode == ''"
              class="fileiconfont my-mind-icon file-color-blue"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 1"
              class="fileiconfont my-mind-icon file-color-yellow"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 2"
              class="fileiconfont my-mind-icon file-color-red"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 3"
              class="fileiconfont my-mind-icon file-color-green"
              >&#xe761;</span
            >
            <span
              v-if="item.colorCode == 4"
              class="fileiconfont my-mind-icon file-color-gray"
              >&#xe761;</span
            >
            <div class="my-mind-list-text">
              <div
                class="my-mind-main-heading"
                :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
                :title="item.name"
              >
                {{ item.name }}
              </div>
              <div
                class="my-mind-main-subheading"
                :style="{ width: fullWidth - titleExceptWidth - calendarViewWidth + 'px' }"
              >
                {{ item.subtitle }}
              </div>
            </div>
            <div class="my-mind-time">
              {{
                dateFormat("YYYY/mm/dd HH:MM", new Date(item.modifyTime * 1000))
              }}
            </div>

            <div
              class="more-menu-list"
              @click.stop="showMenu(item, !showMenuOpen, index)"
            >
              <!-- @mouseover.stop="mouseoverOtherMoreMenu(index)"
              @contextmenu.prevent.stop="" -->
              <a-icon class="more-icon" type="more" />
              <div
                v-show="
                  !!showMenuData &&
                  showMenuData.id == item.id &&
                  showMenuOpen == true
                "
                class="more-menu-list-position"
                :class="'more-menu-list-position-' + index"
                :style="{ top: menuTop + 'px',right:menuRight + 'px' }"
                v-clickoutside="outsideCloseMoreMenu"
              >
                <div @click.stop="pageFile(3, item)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon"
                    src="../../../assets/img/myMindIcon/open01.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.My_Mind_Open) }}
                  </div>
                </div>
                <div @click.stop="moveCheck(item, index)" class="each-menu-box">
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/set03.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Move_To) }}
                  </div>
                </div>

                <div
                  @click.stop="repeatName(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/rename02.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.User_Folder_Rename) }}
                  </div>
                </div>

                <div
                  @click.stop="removeFile(item, index)"
                  class="each-menu-box"
                >
                  <img
                    class="each-menu-box-icon-1"
                    src="../../../assets/img/myMindIcon/delete04.png"
                    alt=""
                  />
                  <div class="each-menu-box-name">
                    {{ getString(strings.Mind_Mindmap_Deleted_Forever) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>    
    <div class="mind-null" v-if="data == null || data.length == 0">
      <p>
        <!-- <img src="../../../assets/img/user/map1135.png" alt=""> -->
        <img class="createdMindEmptyIcon"
          src="../../../../static/img/userData/user_collection_null_logo_MyMind.svg"
          alt=""
        />
      </p>
      <!-- {{getString(strings.My_Mind__No_Map_Yet_Go)}}  <span class="createdMind" @click="createdMindPage"> {{getString(strings.My_Mind_Create)}} </span>  {{getString(strings.My_Mind_Right)}}  -->
      <div class="createdMind">
        {{ getString(strings.My_Mind_Is_Null_Tips) }}
      </div>
    </div>
    <div class="calendar-content">
      <div class="calendar-head">
        <div class="calendar-title-label">
          {{currentCalendarTitle}}
        </div>
        <img
          src="../../../assets/img/user/currency/set_up.svg"
          class="calendar-icon-help"
          @click="onClickHelp()"
        />
        <div class="calendar-date-up" @click="onUpMonth()" >  
          〈 
        </div>
        <div class="calendar-date-down" @click="onNextMonth()">
          〉
        </div>
      </div>
      <div class="calendar-data">
        <template v-if="calendarData != null">
          <div
            v-for="(item, index) in calendarData"
            :key="index"
            class="calendar-date-content"
            :class="item.className + ' ' + item.cursor + ' ' + 
            ((currentDay == item.date && currentMonthIndex == item.month && currentYear == item.year) ? 'calendar-date-content-selected' : '') "
             @click="onClickDay(item)"
          >
            <div class="calendar-date-message" v-show="item.messages.length > 0" :style="'background:' + item.color">
            </div>
            <div class="calendar-date-label" 
                :class="item.labelClass">
              {{ item.label }}
            </div>
            <div class="calendar-date-message-status" v-show="item.status.length > 0" >
            {{item.status}}
            </div>
          </div>
        </template>
      </div>      
      <div class="calendar-message"> 
        <img v-show="showAddCalendarMessageBt"
          src="../../../assets/img/mindNewEditMenuIocn/insert.svg"
          class="add-calendar-message"
          @click="onClickAddCalendarMessage()"
        />
        <div class="add-calendar-message-input-content" v-show="showAddCalendarMessageInput"> 
          <a-select
                class="calendar-message-type"
                v-model="calendarMessageType"
                defaultValue="sortOrder"
                style="width: 66px; height: 28px; font-size: 13px"
                @change="onChangeCalendarMessageType"
              >
            <a-icon slot="suffixIcon" style="color: #333" type="caret-down" />
            <a-select-option
              v-for="item of calendarDataTypes"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <a-select v-show="showCalendarMessageStatus"
                class="calendar-message-status"
                v-model="calendarMessageStatus"
                defaultValue="sortOrder"
                @change="onChangeCalendarStatus"
              >
            <a-icon slot="suffixIcon" style="color: #333" type="caret-down" />
            <a-select-option
              v-for="item of calendarStatusDatas"
              :key="item"
              :value="item"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <a-input v-show="!showCalendarMessageStatus"
            v-model="addCalendarMessage"
            class="add-calendar-message-input"
            :maxLength="64"
            ref="fileInput"
            :placeholder="getString(strings.Mind_Calendar_Input_Tip)"
            @keyup.enter="addeCalendarMessage()"
          /> 
        </div>
        <template v-if="currentCalendarMessage.length > 0" >  
          <div v-for="(item, index) in currentCalendarMessage"
            :key="index">
            <template v-if="isElectron || item.data == null" >                   
              <div v-show="currentCalendarMessage != null"
                class="calendar-message-cell-content"
                @mouseover="onMouseoverMindByCalendarCell(index)"
                @mouseout="onMouseoutMindByCalendarCell(index)"
                @click="onClickMindByCalendar(item)">
                  <div class="calendar-message-cell-tag" :style="'background:' + item.color">
                  </div>
                  <div class="calendar-message-cell-label" >
                    {{ item.label }}
                  </div>
                  <img :id="'calendar-message-delete-' + index" style="display:none"
                    src="../../../assets/img/canvasMenu/darkModeIcon/deletedForeverWhite.svg"
                    class="user-calendar-message-delete"
                    @click.stop="onClickDeleteCalendarMessage(item, $event)"
                  />
              </div>
            </template>
            <template v-else>
              <div v-show="currentCalendarMessage != null"                  
                  @mouseover="onMouseoverMindByCalendarCell(index)"
                  @mouseout="onMouseoutMindByCalendarCell(index)"
                  class="calendar-message-cell-content"> 
                  <a
                      :href="'/mindmap?id=' + item.data.mindMapId + '&t=' + item.data.modifyTime"
                      target="_blank"
                      @mouseover="onMouseoverMindByCalendarCell(index)"
                      @mouseout="onMouseoutMindByCalendarCell(index)"
                      class="calendar-message-cell-content">              
                        <div class="calendar-message-cell-tag" :style="'background:' + item.color">
                        </div>
                        <div class="calendar-message-cell-label" >
                          {{ item.label }}
                        </div>
                  </a>
                  <img :id="'calendar-message-delete-' + index" style="display:none"
                    src="../../../assets/img/canvasMenu/darkModeIcon/deletedForeverWhite.svg"
                    class="user-calendar-message-delete"
                    @click.stop="onClickDeleteCalendarMessage(item, $event)"
                  />
              </div>
            </template>
          </div>
        </template>
        <div class="calendar-message-empty" v-show="currentCalendarMessage.length == 0">
          {{ getString(strings.Global_Text_Find_Empty) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HttpServerConfig from "../../../common/HttpServerConfig";
import { mapMutations } from "vuex";
import Template from "../../../views/template/Template.vue";
import getString from "../../../common/lang/language";
import strings from "../../../common/lang/strings";
import ConfigurationItem from "../../../assets/js/ConfigurationItem.js";
const { numberOfMaps } = ConfigurationItem;
import Config from "../../../core/core/calcule/Config";
import Util from "../../../utils/Util";
import XJMindFIleImport from '../../../core/core/dataformat/fileImport/XJMindFIleImport';
import CalendarData from '../../../viewmodel/core/minddata/CalendarData';
import CalendarDataMessage from '../../../viewmodel/core/minddata/CalendarDataMessage';
import Colors from '../../../utils/Colors';
import UserCalendarData from '../../../viewmodel/core/minddata/UserCalendarData';
import MindMe from '../../../viewmodel/facade/MindMe';

if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
}
export default {
  name: "MyMindContent",
  props: ["show", "data", "showPop"],
  data() {
    return {
      fullWidth: document.documentElement.clientWidth,
      fullHeight: document.documentElement.clientHeight,
      showMenuData: {}, //当前展示的菜单的对象
      showMenuOpen: false, //菜单是否展开
      showAddCalendarMessageBt: false,
      showAddCalendarMessageInput: false,
      showCalendarMessageStatus: false,
      addCalendarMessage:"",
      isElectron: false, //是否是electron
      strings,
      showIndex: -1,
      menuTop: 0,
      menuRight: 30,
      titleExceptWidth: 520,
      timerOtherMoreMenu: null,
      calendarViewWidth: 245,
      calendarData:[],
      currentYear:2024,
      currentDay: -1,
      currentMonthIndex:0,
      currentCalendarTitle:"",
      currentCalendarMessage: [],
      userCalendarDatas:null,
      currentEditCalenderMessageId:-1,
      calendarMessageType: "待办",
      calendarMessageStatus: "无状态",
      calendarMessageTypeSeparator: "：",
      calendarDataTypes: ["待办", "事件", "状态"],
      calendarStatusDatas: ["无状态", "美滋滋  😊", "元气满满  💪", "裂开  💔","沮丧  😓", "疲惫  😩", "忙碌  🏃", "休憩  🧘"],
    };
  },
  created() {
    this.isElectron = this.$tools.isElectron();
  },

  mounted() {
    this.get_bodyWidth();
    this.setViewHeight();
    this.initCalendarData();
    this.setCalendarData(this.currentYear, this.currentMonthIndex);
  },
  methods: {
    ...mapMutations(["sharePotatoChipsModel"]),
    getString(i) {
      return getString(i);
    },
    get_bodyWidth() {
      //动态获取浏览器高度
      const that = this;
      window.addEventListener("resize", () => {
        that.fullWidth = document.documentElement.clientWidth;
        that.fullHeight = document.documentElement.clientHeight;
        that.setViewHeight();
      });

    },

    initCalendarData() {
      const date = new Date();
      this.currentYear = date.getFullYear()
      this.currentMonthIndex = date.getMonth()  
      this.userCalendarDatas = MindMe.getUserCalendarDatas()    
    },

    onMouseoverMindByCalendarCell(index) {
      let deleteBt = document.getElementById("calendar-message-delete-" + index)
      if (deleteBt != null && deleteBt.style != null) {
        deleteBt.style.display = "block"
      }
    },

    onMouseoutMindByCalendarCell(index) {
      let deleteBt = document.getElementById("calendar-message-delete-" + index)
      if (deleteBt != null && deleteBt.style != null) {
        deleteBt.style.display = "none"
      }
    },

    onClickMindByCalendar(data) {            
      if (data.data != null) {
        this.openMind(data.data)
      } else if (data.label != "") {  
        this.showAddCalendarMessageInput = true
        this.showAddCalendarMessageBt = false
        this.addCalendarMessage = this.stripCalendarMessageTypeByLabel(data.label)
        this.showCalendarMessageStatus = this.calendarDataTypes[2] == this.calendarMessageType
        this.currentEditCalenderMessageId = data.id        
      }
    },
    onChangeCalendarMessageType(value) {
      this.calendarMessageType = value
      this.showCalendarMessageStatus = this.calendarDataTypes[2] == value
    },
    isShowCalendarMessageStatus(messages) {
      if (messages == null || messages.length == 0) {
        return false;
      }
      for (let index = 0; index < messages.length; index++) {
        const element = messages[index];
        if (element.status != null && element.status.length > 0) {
          return true
        }
      }
      return false;
    },
    getCalendarMessageStatus(messages) {
      if (messages == null || messages.length == 0) {
        return "";
      }
      for (let index = 0; index < messages.length; index++) {
        const element = messages[index];
        if (element.status != null && element.status.length > 0) {
          return element.status;
        }
      }
      return "";
    },
    onChangeCalendarStatus(value) {
      this.calendarMessageStatus = value
      this.addCalendarMessage = this.calendarStatusDatas[0] == value ? "" : value
      this.addeCalendarMessage()
    },

    onClickAddCalendarMessage() {
      this.showAddCalendarMessageInput = true
      this.showAddCalendarMessageBt = false
      this.addCalendarMessage = ""
      this.currentEditCalenderMessageId = -1
    },

    removeCalendarMessageForMindMap(mindMapId) {
      for (let index = 0; index < this.data.length; index++) {
        const mindDataCell = this.data[index];
        if (mindDataCell.mindMapId == mindMapId) {
          this.data.splice(index, 1)
          this.setCalendarDataInfo()
          break
        }
      }
    },

    onClickDeleteCalendarMessage(data, event) {      
      event.stopPropagation()
      if (data.data != null) {
        let obj = {};
        obj.index = 0;
        obj.item = data.data;
        this.$emit("removeFile", obj);
        return
      }
      for (let index = 0; index < this.calendarData.length; index++) {
        const element = this.calendarData[index];        
        for (let index = 0; index < element.messages.length; index++) {
          const messageCell = element.messages[index];
          if (messageCell == data) {
            messageCell.label = ""
            let deleteData = new UserCalendarData()
            deleteData.id = messageCell.id
            deleteData.label = "";
            deleteData.year = element.year
            deleteData.month = element.month
            deleteData.date = element.date
            this.showAddCalendarMessageInput = false
            this.showAddCalendarMessageBt = true
            this.addCalendarMessage = ""   
            this.currentEditCalenderMessageId = -1  
            this.userCalendarDatas = MindMe.setUserCalendarData(deleteData)
            this.setCalendarDataInfo();  
            break
          }
        }
      }   
    },

    addeCalendarMessage() {      
      this.showAddCalendarMessageInput = false
      this.showAddCalendarMessageBt = true
      var id = 0
      var isChangeData = false
      for (let index = 0; index < this.currentCalendarMessage.length; index++) {
        const element = this.currentCalendarMessage[index];
        if (element.data == null) {
          if (element.id == this.currentEditCalenderMessageId && this.currentEditCalenderMessageId > 0) {
            var label = this.addCalendarMessage
            if (label.length > 0) {
              label = this.calendarMessageType + this.calendarMessageTypeSeparator + label
            }
            element.label = label
            id = element.id
            isChangeData = true
            break
          }
          id = Math.max(id, element.id)
        }
      }

      if (!isChangeData && this.addCalendarMessage.length == 0) {
        return
      }
      let data = new UserCalendarData()
      data.id = isChangeData ? id : id + 1
      var label = this.addCalendarMessage
      if (label.length > 0) {
        label = this.calendarMessageType + this.calendarMessageTypeSeparator + label
      }
      
      data.label = label;
      data.year = this.currentYear
      data.month = this.currentMonthIndex
      data.date = this.currentDay
      this.userCalendarDatas = MindMe.setUserCalendarData(data)  
      this.addCalendarMessage = ""   
      this.currentEditCalenderMessageId = -1   
      this.setCalendarDataInfo();
    },

    stripCalendarMessageTypeByLabel(label) {
      if (label == null || label.length == 0) {
        return ""
      }

      for (let index = 0; index < this.calendarDataTypes.length; index++) {
        const element = this.calendarDataTypes[index];
        if (label.indexOf(element + this.calendarMessageTypeSeparator) == 0) {
          this.calendarMessageType = element;
          let itemLabel = label.substr((element + this.calendarMessageTypeSeparator).length)
          if (index == 2) {
            this.calendarMessageStatus = itemLabel
          }
          return itemLabel
        }
      }
      return label
    },

    onClickHelp() {
      let that = this;
      this.$confirm({
          title: getString(strings.Mind_Tips),
          content: getString(strings.Mind_Calendar_Tip),
          centered: true,
          okText: getString(strings.Global_Ok),
          cancelText: getString(strings.Global_Cancel),
          onOk() {            
          },
          onCancel() {},
        });
    },

    onClickDay(data) {
      if (data.date <= 0) {
        return
      }
      if (data.month == this.currentMonthIndex &&
        data.year == this.currentYear && 
        data.date == this.currentDay) {
          this.currentDay = -1;
          this.showAddCalendarMessageBt = false;
          this.showAddCalendarMessageInput = false
          this.setCurrentCalendarMessage();
          return
      }
      if (data.month != this.currentMonthIndex) {
        this.currentYear = data.year;
        this.currentMonthIndex = data.month; 
        this.currentDay = -1;
        this.showAddCalendarMessageBt = false;
        this.setCalendarData(this.currentYear, this.currentMonthIndex);        
        this.setCalendarDataInfo();
      }
      this.currentDay = data.date;
      this.showAddCalendarMessageBt = true;
      this.showAddCalendarMessageInput = false;
      this.setCurrentCalendarMessage();
    },

    onUpMonth() {
      this.currentYear = this.currentMonthIndex == 0 ? this.currentYear - 1 : this.currentYear;
      this.currentMonthIndex = this.currentMonthIndex == 0 ? 11 : this.currentMonthIndex - 1; 
      this.currentDay = -1;
      this.showAddCalendarMessageBt = false;
      this.showAddCalendarMessageInput = false
      this.setCalendarData(this.currentYear, this.currentMonthIndex);
      this.setCalendarDataInfo();
    },

    onNextMonth() {
      this.currentYear = this.currentMonthIndex == 11 ? this.currentYear + 1 : this.currentYear;
      this.currentMonthIndex = this.currentMonthIndex == 11 ? 0 : this.currentMonthIndex + 1; 
      this.currentDay = -1;
      this.showAddCalendarMessageBt = false;
      this.showAddCalendarMessageInput = false
      this.setCalendarData(this.currentYear, this.currentMonthIndex);
      this.setCalendarDataInfo();
    },

    setCalendarData(year, monthIndex) {
      this.currentCalendarTitle = year + "年" + (monthIndex + 1) + "月";
      const date = new Date();
      let isTodaySameMonth = date.getFullYear() == year && date.getMonth() == monthIndex
      let today = date.getDate()
      let designatedOneWeek = new Date(year, monthIndex, 1).getDay();

      let upYear = monthIndex == 0 ? year - 1 : year;
      let upMonthIndex = monthIndex == 0 ? 11 : monthIndex - 1; 
      let upDays = Util.getDaysOfMonth(upYear, upMonthIndex)

      let days = Util.getDaysOfMonth(year, monthIndex )

      let nextYear = monthIndex == 11 ? year + 1 : year;
      let nextMonthIndex = monthIndex == 11 ? 0 : monthIndex + 1; 
      let nextDays = Util.getDaysOfMonth(nextYear, nextMonthIndex) 
      
      let week = {0:"一", 1:"二", 2:"三", 3:"四", 4:"五", 5:"六", 6:"日"}
      var list = []
      for (let index = 0; index < 7; index++) {
        const element = new CalendarData (index, week[index], 0)
        list.push(element)
      }
      let upMonthDays = designatedOneWeek == 0 ? 6 : designatedOneWeek - 1;
      for (let index = 0; index < upMonthDays; index++) {
        let date = upDays - upMonthDays + index + 1; 
        const element = new CalendarData (list.length, "" + date, date)
        element.year = upYear
        element.month = upMonthIndex
        element.cursor = "cursor-pointer"
        element.labelClass = "calendar-date-label-disenable"
        list.push(element)
      }
      for (let index = 0; index < days; index++) {
        let date = index + 1; 
        const element = new CalendarData (list.length, "" + date, date)
        element.year = year
        element.month = monthIndex
        element.cursor = "cursor-pointer"
        if (isTodaySameMonth && today == index + 1) {
          element.className = "calendar-date-content-today"
        }
        list.push(element)
      }
      for (let index = 0; index < 20; index++) {
        let date = index + 1; 
        const element = new CalendarData (list.length, "" + date, date)
        element.year = nextYear
        element.month = nextMonthIndex
        element.cursor = "cursor-pointer"
        element.labelClass = "calendar-date-label-disenable"
        list.push(element)
        if (list.length == 49) {
          break
        }
      }
      this.calendarData = list
    },

    setCurrentCalendarMessage() {
      var messages = []
      
      
      for (let index = 0; index < this.calendarData.length; index++) {
        const element = this.calendarData[index];
        if (this.currentDay == -1 || 
          (element.date == this.currentDay && 
          element.year == this.currentYear && 
          element.month == this.currentMonthIndex)) {
          for (let index = 0; index < element.messages.length; index++) {
            const messageCell = element.messages[index];
            if (messageCell.data == null || !messageCell.data.isDir) {
              messages.push(messageCell)
            }
          }
        }
      }
      this.currentCalendarMessage = messages
    },

    setCalendarDataInfo() {
      // console.log(this.data);
      for (let index = 0; index < this.calendarData.length; index++) {
        const element = this.calendarData[index];
        var messages = []
        for (let j = 0; j < this.data.length; j++) {
          const mindDataCell = this.data[j];
          let createDate = new Date(mindDataCell.createTime * 1000);
          let modifyDate = new Date(mindDataCell.modifyTime * 1000);
          let createDateYear = createDate.getFullYear();
          let createDateMonth =  createDate.getMonth();
          let createDay = createDate.getDate()

          let modifyDateYear = modifyDate.getFullYear();
          let modifyDateMonth =  modifyDate.getMonth();
          let modifyDay = modifyDate.getDate()
          let isCreate = element.year == createDateYear && element.month == createDateMonth && element.date == createDay;
          let isModify = element.year == modifyDateYear && element.month == modifyDateMonth && element.date == modifyDay;
          
          if (isCreate || isModify) {
            let dataMessage = new CalendarDataMessage()
            dataMessage.label = mindDataCell.name            
            dataMessage.data = mindDataCell
            if (isCreate && isModify) {
              dataMessage.color = Colors.getUiColor(0x29A73B)
            } else if (isCreate) {
              dataMessage.color = Colors.getUiColor(0xFF4A4A)
            } else {
              dataMessage.color = Colors.getUiColor(0x1B86F2)
            }
            messages.push(dataMessage)
          }
        }
        let itemStatus = ""
        if (this.userCalendarDatas != null && this.userCalendarDatas.list.length > 0) {
          for (let j = 0; j < this.userCalendarDatas.list.length; j++) {
            const userDataCell = this.userCalendarDatas.list[j];
            if (userDataCell.label == null || userDataCell.label.length == 0) {
              continue;
            }
            
            if (userDataCell.year == element.year && userDataCell.month == element.month && userDataCell.date == element.date) {
              let dataMessage = new CalendarDataMessage(userDataCell.id)
              dataMessage.label = userDataCell.label            
              dataMessage.data = null
              dataMessage.color = Colors.getUiColor(0xFF8413)
              if (dataMessage.label != null) {
                for (let g = 0; g < this.calendarStatusDatas.length; g++) {
                  if (dataMessage.label.indexOf(this.calendarStatusDatas[g]) > -1) {
                    let status = this.calendarStatusDatas[g].substring(this.calendarStatusDatas[g].indexOf("  ") + "  ".length, this.calendarStatusDatas[g].length)
                    dataMessage.status = status
                    itemStatus = status
                  }
                }
              }
              messages.push(dataMessage)
            }
          }
        }
        element.status = itemStatus
        element.messages = messages
        if (messages.length > 0) {
          element.color = messages[0].color
        }
      }
      this.setCurrentCalendarMessage()
    },

    setViewHeight() {
      let content1 = document.getElementsByClassName("my-mind-content-1")
      let content2 = document.getElementsByClassName("my-mind-content-2")
      let calendarContent = document.getElementsByClassName("calendar-content")
      let calendarMessage = document.getElementsByClassName("calendar-message")
      
      if (content1 != null && content1.length > 0) {
        content1[0].style.height = (this.fullHeight - 240) + "px";
      }
      if (content2 != null && content2.length > 0) {
        content2[0].style.height = (this.fullHeight - 240) + "px";
      }
      if (calendarContent != null && calendarContent.length > 0) {
        calendarContent[0].style.height = (this.fullHeight - 250) + "px";
      }
      if (calendarMessage != null && calendarMessage.length > 0) {
        calendarMessage[0].style.height = (this.fullHeight - 250 - 245 - 35 - 20) + "px";
      }
      
    },

    checkImgSrc(src, item) {
      if (item != null && item.isHistory) {
        return "../../../assets/img/user/mind_file_icon.svg"
      }
      return "../../../assets/img/user/mind_file_icon.svg";//"HttpServerConfig.getMediaSrc(src)";
    },
    showMenu(item, isCheck, index) {
      this.showMenuData = item;
      //菜单是否展开
      if (this.showIndex == index) {
        //点击相同的菜单显示或隐藏，不同的菜单显示
        this.showMenuOpen = isCheck;
      } else {
        this.showMenuOpen = true;
      }
      if (index != undefined && index >= 0) {
        this.showIndex = index;
      }
      this.$emit("closeRightMenu");
      let event = window.event;
      if (this.show != 1 && event != null && event.x < 345) {
        this.menuRight = -60
      } else {
        this.menuRight = 30
      }
      // let event = window.event;
      // let left = event.offsetX;
      // this.menuRight = left
      // console.log(left,event);
    },
    mouseoverOtherMoreMenu(index) {
      // if (this.showMenuOpen) {
      //   clearTimeout(this.timerOtherMoreMenu)
      //   this.timerOtherMoreMenu = setTimeout( () => {
      //     console.log(this.showIndex);
      //     if (this.showIndex != index) {
      //       this.showMenuOpen = false;
      //     }
      //     this.showIndex = index;
      //   },200)
      // }
      if (this.showMenuOpen) {
        if (this.showIndex != index) {
          this.showMenuOpen = false;
        }
        this.showIndex = index;
      }
    },
    outsideCloseMoreMenu() {
      this.showMenuOpen = false;
    },
    gridCloseMoreMenu() {
      if (this.show == 2) {
        this.outsideCloseMoreMenu();
      }
    },
    shareFile(item, index) {
      this.sharePotatoChipsModel({ accout: true, shareData: item });
    },
    //重命名
    folderRenameMap(item, index) {
      let obj = {};
      obj.item = item;
      obj.index = index;
      this.$emit("folderRenameMap", obj);
    },
    copyMap(item, index) {
      let obj = {};
      obj.item = item;
      obj.index = index;
      this.$emit("copyMap", obj);
    },
    moveCheck(item, index) {
      let obj = {};
      obj.item = item;
      obj.index = index;
      this.$emit("moveCheck", obj);
      this.outsideCloseMoreMenu();
    },
    removeFile(item, index) {
      if (item.isHistory && item.path != null) {
        new XJMindFIleImport(null, null, this.$router.appPath).removeHistory(item.path, item.localpath, item.name)
        let count = this.data.length
        for (let index = 0; index < count; index++) {
          if (this.data[index].isHistory && this.data[index].path == item.path) {
              this.data.splice(index, 1)
              break
          }
        }
        let id = 'my-mind-list' + item.mindMapId
        let element = document.getElementById(id)
        if (element != null && element.parentElement != null &&  element.parentElement.parentElement != null) {
          element.parentElement.parentElement.remove()
        }
      } else {
        let obj = {};
        obj.index = index;
        obj.item = item;

        this.$emit("removeFile", obj);
      }
      
      this.outsideCloseMoreMenu();
    },
    importGroup(item, index) {
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("importGroup", obj);
      this.outsideCloseMoreMenu();
    },
    importArchive(item, index) {
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("importArchive", obj);
      this.outsideCloseMoreMenu();
    },
    openMind(item) {
      //打开导图
      if (item.isHistory) {
        if (item.id != null && item.id.length > 10 && item.id.indexOf("-local") == -1) {
            ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(item.path) +
                            "&localpath=" + encodeURIComponent(item.localpath) + 
                            "&id=" + item.id + 
                            "&t=" + item.modifyTime);
        } else {
            ipcRenderer.send("openCalendarWindow", "/mindmap?path=" + encodeURIComponent(item.path) +
                            "&localpath=" + encodeURIComponent(item.localpath) + 
                            "&t=" + item.modifyTime);
        }
        new XJMindFIleImport(null, null, this.$router.appPath).addHistory(item.path, item.localpath, item.name, item.id)
      } else {
        if (item.path != null && item.path.length > 0 && item.localpath != null && item.localpath.length > 0) {
          ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + item.mindMapId + 
                            "&path=" + encodeURIComponent(item.path) + 
                            "&localpath=" + encodeURIComponent(item.localpath));
        } else {
          ipcRenderer.send("openCalendarWindow", "/mindmap?id=" + item.mindMapId + 
                            "&t=" + item.modifyTime);
        }
      }
    },
    pageFile(index, item) {
      if (!item.isDir) {
        //如果是点击打开导图
        let aLink = document.getElementById("my-mind-list" + item.mindMapId);
        aLink.click();
        return;
      }
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("pageFile", obj);
    },
    // 重命名文件夹
    repeatName(item, index) {
      let obj = {};
      obj.index = index;
      obj.item = item;
      this.$emit("repeatName", obj);
      this.outsideCloseMoreMenu();
    },
    hidden() {},
    createdMindPage() {
      this.$router.push("/Home/CreatedFile");
    },
    // 已登录免费用户导图数量超过2个，则每次打开都弹购买弹窗
    mapsNumberMoreThanTwo() {
      const mapsNumber = localStorage.getItem("mapsNumber") || 0;
      const meMessageStr = localStorage.getItem("meMessage");
      const meMessage = meMessageStr != null ? JSON.parse(meMessageStr) : null;
      if (mapsNumber >= numberOfMaps && meMessage && !meMessage.isMember) {
        // 已登录 弹付费窗 30分钟弹出一次
        let showShoppingMoreThanTwoTime = "showShoppingMoreThanTwoTime";
        let showShoppingTime = localStorage.getItem(
          showShoppingMoreThanTwoTime
        );
        let nowTime = Util.getCurrentTime();
        if (showShoppingTime) {
          let time = nowTime - showShoppingTime;
          if (time > Config.MAPS_MORE_THAN_TWO_TIME) {
            this.$store.state.showShopping = true;
            localStorage.setItem(showShoppingMoreThanTwoTime, nowTime);
          }
        } else {
          localStorage.setItem(showShoppingMoreThanTwoTime, nowTime);
          let firstOpenPage = sessionStorage.getItem("firstOpenPage");
          if (!firstOpenPage) {
            sessionStorage.setItem("firstOpenPage", true);
            this.$store.state.showShopping = true;
          }
        }
      }
    },
    dateFormat(fmt, date) {
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
  watch: {
    data(newVal) {
      // if (newVal) {
      //   let len = newVal.length;
      //   for (let i = 0; i < len; i++) {
      //     let date = new Date(newVal[i].modifyTime * 1000);
      //     newVal[i].modifyTime = this.dateFormat("YYYY/mm/dd HH:MM", date);
      //   }
      // }
      localStorage.setItem("mapsNumber", this.data.length);
      this.setCalendarDataInfo()
    },
    showIndex: {
      handler(newVal) {
        var e = window.event;
        if (e.pageY + 392 > this.fullHeight) {
          this.menuTop = this.fullHeight - (e.pageY + 392);
        } else {
          this.menuTop = 0;
        }
        if (this.showMenuData.isDir && e.pageY + 232 > this.fullHeight) {
          this.menuTop = this.fullHeight - (e.pageY + 232);
        }
      },
    },
  },
  destroyed() {},
};
</script>

<style  lang="less">
.my-mind-content-father-box {
  margin-top: 40px;
  /* 文件夹颜色开始 */
  .file-color-green {
    color: #43a102;
  }
  .file-color-blue {
    color: #4499ee;
  }
  .file-color-red {
    color: #ff6600;
  }
  .file-color-yellow {
    color: #eed205;
  }
  .file-color-gray {
    color: #707070;
  }
  /* 文件夹颜色结束 */

  .mind-null {
    /* height: 100px; */
    padding-top: 7em;
    color: #ccc;
    padding-bottom: 40px;
    text-align: center;
    /* line-height: 100px; */
    font-size: 18px;
    margin-right: 250px;
    top: -500px;
    position: relative;
  }

  .calendar-content {
    width: 265px;
    position: fixed;
    display: block;
    // align-items: center;
    background: #f5f5f5;
    border-radius: 8px;
    right: 10px;
    top: 240px;
    height: 100%;

    .calendar-head {
        height: 35px;
        align-items: center;

        .calendar-title-label {
          font-size: 16px;
          color: #111;
          font-weight: 700;
          margin-left: 20px;
          margin-top: 15px;
        }
        .calendar-icon-help {
          width: 20px;
          height: 20px;
          padding: 2px;
          margin-left: 10px;
          border-radius: 2px;
          position: absolute;
          top: 15px;
          right: 75px;
          cursor: pointer;
        }
        .calendar-date-up {
          width: 20px;
          height: 20px;
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
          border-radius: 2px;
          position: absolute;
          top: 15px;
          right: 45px;
          cursor: pointer;
        }
        .calendar-date-up:hover {
          background: #dadada;
        }
        .calendar-date-down {
          width: 20px;
          height: 20px;
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
          border-radius: 2px;
          position: absolute;
          top: 15px;
          right: 10px;
          padding-left: 7px;
          cursor: pointer;
        }
        .calendar-date-down:hover {
          background: #dadada;
        }
    }

    .calendar-data {
      width: 245px;
      height: 245px;
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;      
      align-content:flex-start;

      .calendar-date-content {
        width: 35px;
        height: 35px;
        border-radius: 17.5px;
        align-content: center;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .calendar-date-label {
          color: #666666;
          border-radius: 12px;
          font-size: 13px;
          text-align: center;
        }
        .calendar-date-message {
          width: 6px;
          height: 6px;
          position: absolute;
          left: 14.5px;
          top: 4px;
          border-radius: 3px;
          background: #43a102;
        }
        .calendar-date-message-status {
          font-size: 13px;
          position: absolute;
          text-align: center;
          margin-left: 0px;
          bottom: -7px;
        }
        .calendar-date-label-disenable {
          color: #adadad;
          font-size: 13px;
        }
      }

      .calendar-date-content-today {
        background: #ff9c5f;

        .calendar-date-label {
          color: #fff;
          font-weight: 700;
        }
      }
      .calendar-date-content-selected {
        background: #e775fe;

        .calendar-date-label {
          color: #fff;
          font-weight: 700;
        }
      }

      .cursor-pointer {
        cursor: pointer;
      }
      .calendar-date-content:hover {
        background: #dadada;
      }
      .calendar-date-content-today:hover {
        background: #ff9c5f;
      }
      .calendar-date-content-selected:hover {
        background: #e775fe;
      }
    }
    
    .calendar-message {
      width: 100%;
      margin-top: 10px;
      overflow-y: scroll;
      overflow-x: hidden;

      .add-calendar-message {
        width: 24px;
        height: 24px;
        padding: 2px;
        border-radius: 12px;
        margin-left: 10px;
        cursor: pointer;
      }
      .user-calendar-message-delete {
        width: 24px;
        height: 24px;
        padding: 3px;
        border-radius: 12px;
        margin-left: 10px;
        position: relative;
        float: right;
        right: 5px;
        top: -30px;        
        cursor: pointer;
        background: #ff9d9d;
      }
      .user-calendar-message-delete:hover {
        background: #ff1414;
      }
      // .add-calendar-message-input {
      //   width: 223px;
      //   height: 40px;
      //   color: #111;
      //   margin-left: 20px;
      //   margin-right: 20px;
      //   border-radius: 4px;
      // }
      .add-calendar-message-input-content {
        height: 32px;
        border-radius: 4px;
        border: solid 1px #b5b5b5;
        background: #fff;
      }
      .calendar-message-type {
        width: 66px; 
        height: 28px; 
        font-size: 13px;
        border-radius: 4px;
        color: #222222;
        border: #ffffff00;
        background: #ffffff00;

        .ant-select-selection {
          background: #ffffff00;
        }
      }
      .calendar-message-status {
        width: 185px;
        height: 28px; 
        font-size: 13px;
        border-radius: 4px;
        color: #222222;
        border: #ffffff00;
        background: #ffffff00;

        position: relative;
        left: 66px;
        top: -29px;
        margin-left: 0px;
        margin-right: 20px;

        .ant-select-selection {
          background: #ffffff00;
          border: solid 0 #ffffff00;
        }
        .ant-select-selection-selected-value {
          width: 150px;
        }
      }
      .add-calendar-message-input {
        width: 185px;
        height: 32px;
        position: relative;
        left: 66px;
        top: -29px;
        color: #111;
        margin-left: 0px;
        margin-right: 20px;
        border-radius: 4px;
        border: solid 0 #ffffff00;
        background: #ffffff00;
      }
      .add-calendar-message:hover {
        background: #dadada;
      }
      .calendar-message-cell-content:hover {
        background: #dadada;
      }
      .calendar-message-cell-content {
        width: 100%;
        height: 34px;
        border-radius: 4px;
        cursor: pointer;

        .calendar-message-cell-tag {
          width: 6px;
          height: 14px;
          position: relative;
          left: 20px;
          top: 10px;
          border-radius: 2px;
        }

        .calendar-message-cell-label {
          width: 203px;
          font-size: 13px;
          // font-weight: 700;
          color: #555555;
          position: relative;
          left: 34px;
          top: -7px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .calendar-message-empty {
        width: 240px;
        height: 100%;
        align-content: center;
        align-items: center;
        margin-top: 10px;
        font-size: 13px;
        color: #808080;
        text-align: center;
      }
    }
  }
  /* 列表视图 */
  .my-mind-content-1 {
    // margin-top: 3em;
    margin-right: 250px;
    overflow-y: scroll;
  }

  .my-mind-content-1 .my-mind-list {
    position: relative;
    display: flex;
    height: 60px;
    align-items: center;
    border-bottom: 1px solid #eee;
  }
  .my-mind-content-1 .my-mind-item {
    height: inherit;
    position: relative;
    display: flex;
    align-items: center;
  }
  .my-mind-content-1 .my-mind-list:hover {
    // background-color: #ddd;
    background-color: #eee;
  }

  .my-mind-content-1 .my-mind-list .my-mind-img {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 30px;
    line-height: 31px;
    text-align: center;
    margin-left: 20px;
    // border: 1px solid #e9e9e9; 
    /* box-shadow: 0 0 5px #999; */
    border-radius: 6px;
    border-radius: 2px;
  }
  .local-cloud-icon {
    position:absolute;
    width: 20px;
    height: 20px;
    left: 68px;
    top: 30px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-icon {
    display: block;
    width: 40px;
    height: 40px;
    font-size: 40px;
    line-height: 41px;
    text-align: center;
    margin-left: 20px;
    cursor: pointer;
  }
  .my-mind-content-1 .my-mind-list .my-mind-list-text {
    margin: 0 20px 0 15px;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-heading {
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    cursor: pointer;
    min-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .my-mind-content-1 .my-mind-list .my-mind-main-subheading {
    width: 100%;
    /* width: 240px; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
    box-sizing: border-box;
  }
  .my-mind-content-1 .my-mind-list .my-mind-time {
    // margin-left: auto;
    // margin-right: 40px;
    font-size: 12px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
  }
  .my-mind-content-1 .gray-box {
    position: absolute;
    /* visibility:hidden; */
    /* z-index: -1; */
    opacity: 0;
  }
  .my-mind-content-1 .my-mind-list:hover .gray-box {
    opacity: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    visibility: visible;
  }

    /* .my-mind-content-1 .my-mind-list:hover .gray-box-active{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
  } */
  .my-mind-content-1 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    /* background-color: lime; */
  }
  .my-mind-content-1 .more-function {
    margin-left: auto;
    margin-right: 20px;
    color: #333;
  }
  .my-mind-content-1 .my-mind-time-title {
    // margin-left: auto;
    margin-right: 55px;
  }

  .my-mind-content-1 .my-mind-list-head .my-mind-img {
    box-shadow: 0 0 5px #fff;
  }

  .more-menu-list {
    text-align: center;
    width: 40px;
    height: 30px;
    margin-left: 20px;
    position: relative;
    line-height: 30px;
    cursor: pointer;
    .more-icon {
      color: #333;
      font-size: 16px;
      // position: absolute;
      // z-index: 1;
      // left: 50%;
      // top: 50%;
      // transform: translate(-50%,-50%);
    }
    .more-menu-list-position {
      // display: none;
      position: absolute;
      z-index: 2;
      right: 30px;
      top: 0;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 2px 2px 10px #a1a0a0;
      overflow: hidden;
      .each-menu-box {
        display: flex;
        // width: 140px;
        align-items: center;
        // padding-left: 15px;
        padding-top: 8px;
        padding-bottom: 8px;

        .each-menu-box-icon {
          width: 12px;
          height: 7.5px;
          display: block;
          margin-right: 15px;
          margin-left: 30px;
        }
        .each-menu-box-icon-1 {
          width: 12px;
          height: 12px;
          display: block;
          margin-right: 15px;
          margin-left: 30px;
        }

        .each-menu-box-name {
          white-space: nowrap;
          margin-right: 30px;
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
    &:hover {
      display: block;
    }
  }

  /* 网格视图 */
  .my-mind-content-2 {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 250px;
    overflow-y: scroll;
    // .my-mind-list-box{
    //   // margin: 0 auto;
    // }
    
    .my-mind-list {
      width: 116px;
      max-height: 116px;
      margin: 10px 0px 10px 5px;
      padding: 10px;
      position: relative;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
       &:hover {
        // transition: 0.3s all;
        background: #f4f4f5;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
       
      }
      .hover-mind-list{
        &:hover {
          border: 0;
        
        }
      }
      &:hover .more-menu-list {
        display: block;
      }
      .my-mind-icon {
        width: 42px;
        height: 42px;
        font-size: 42px;
        line-height: 42px;
        text-align: center;
        margin-bottom: 15px;
        margin-top: 15px;
        cursor: pointer;
      }
      .my-mind-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .my-mind-img {
        width: 40px;
        height: 40px;
        display: block;
        margin-bottom: 10px;
        margin-top: 15px;
        // background: #ffffff;
        // border: 1px solid #e9e9e9;
        border-radius: 4px;
        cursor: pointer;
      }
      .local-cloud-icon {
        position: absolute;
        width: 20px;
        height: 20px;
        left: 66px;
        top: 42px;
      }

      .my-mind-main-heading {
        width: 96px !important;
        height: 20px;
        font-size: 14px;
        cursor: pointer;
        color: #333333;
        text-align: center;
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .more-menu-list {
        width: 40px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        cursor: pointer;
        margin-left: 20px;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        .more-icon {
          color: #333;
          font-size: 16px;
          // position: absolute;
          // z-index: 1;
          // left: 50%;
          // top: 50%;
          // transform: translate(-50%,-50%);
        }
        .more-menu-list-position {
          // display: none;
          position: absolute;
          z-index: 2;
          right: 30px;
          top: 0;
          background-color: #fff;
          border-radius: 8px;
          box-shadow: 2px 2px 10px #a1a0a0;
          overflow: hidden;
          margin-top: 10px;
          .each-menu-box {
            display: flex;
            // width: 140px;
            align-items: center;
            // padding-left: 15px;
            padding-top: 8px;
            padding-bottom: 8px;

            .each-menu-box-icon {
              width: 12px;
              height: 7.5px;
              display: block;
              margin-right: 15px;
              margin-left: 30px;
            }
            .each-menu-box-icon-1 {
              width: 12px;
              height: 12px;
              display: block;
              margin-right: 15px;
              margin-left: 30px;
            }

            .each-menu-box-name {
              white-space: nowrap;
              margin-right: 30px;
            }
            &:hover {
              background-color: #eee;
            }
          }
        }
        &:hover {
          display: block;
        }
      }
    }
  }
  .my-mind-content-2 .collles {
    width: 180px;
    margin: 10px 0px;
    position: relative;
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 0px 0px 4px 4px;
  }
  .my-mind-content-2 .collles p {
    padding-left: 20px;
    margin: unset;
  }
  .my-mind-content-2 .collles .colimg img {
    width: 180px;
    height: 160px;
  }
  .my-mind-content-2 .collles .colzi {
    font-size: 16px;
    padding-top: 10px;
    font-family: Noto Sans SC;
    /*font-weight: bold;*/
    color: #333333;
  }
  // .my-mind-content-2 .collles .colzi .colriqi{

  // }
  .my-mind-content-2 .my-mind-list .my-mind-time {
    font-size: 12px;
    font-family: Noto Sans SC;
    font-weight: 400;
    color: #333333;
    display: none;
  }
  .my-mind-content-2 .my-mind-main-subheading {
    display: block;
    width: 96px !important;
    height: 20px;
    font-size: 12px;
    cursor: pointer;
    color: #999999;
    text-align: center;
    // white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  /* .my-mind-content-2 .more-function{
  position: absolute;
  right: 0;
  top: 0;
} */
  .my-mind-content-2 .more-father-box {
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 5px;
    /* transform:translateY(-50%); */
    cursor: pointer;
    /* background-color: lime; */
  }
  // .ant-popover-inner-content div:hover{
  //   color: #000;
  // }

  .my-mind-content-2 .more-function {
    margin-left: auto;
    /* margin-right: 20px; */
    color: #666;
  }

  .my-mind-content-2 i.null-box {
    width: 70px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .my-mind-content-2 .gray-box {
    display: none;
  }
  .my-mind-content-2 .my-mind-list:hover .gray-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 1;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
  }
  .createdMind {
    width: 292px;
    height: 40px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    opacity: 1;
    margin: 0 auto;
    margin-top: 40px;
    // color: #ff7c65;
    // cursor: pointer;
    // transition: 0.5s;
  }
  .createdMind:hover {
    width: 292px;
    height: 40px;
    font-size: 14px;
    font-family: Noto Sans SC;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    opacity: 1;
    // color: #fd492b;
    // transition: 0.5s;
    // font-size: 22px;
  }
  .createdMindEmptyIcon {
    -webkit-filter: grayscale(100%);   
    -moz-filter: grayscale(100%);   
    -ms-filter: grayscale(100%);   
    -o-filter: grayscale(100%);     
    filter: grayscale(100%);
    filter: gray;
    width: 200px;
  }
  /* .my-mind-content-2 .more-function{
    margin-left: auto;
    margin-top: 5px;
    margin-right: 5px;
    color: #666;
  } */
}
</style>
